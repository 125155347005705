<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Alianzas</h1>
            <div class="info-card">
                <div class="row mb-3">
                    <div class="col-sm-12">
                        <div class="add-button"
                        v-if="session_user.pages.aliance_create"
                        @click="modalRegister()">
                            <div><span class="material-icons-sharp">add</span>Nueva Alianza</div>
                        </div>
                    </div>
                </div>
                <div v-if="loaded">
                    <input type="text" class="form-control mb-2" placeholder="Buscar alianza....."
                    v-model="search_sp">
                    <table class="table table-striped" >
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>NIT</th>
                                <th>Ciudad</th>
                                <th>Representante</th>
                                <th>Tipo</th>
                                <th>Modalidad</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sale_point in sale_points.data" :key="sale_point.id">
                                <td>{{sale_point.id}}</td>
                                <td>{{sale_point.name}}</td>
                                <td>{{sale_point.nit}}</td>
                                <td>{{sale_point.city_name}}</td>
                                <td>{{sale_point.representative}}</td>
                                <td>{{sale_point.type}}</td>
                                <td>{{sale_point.modality}}</td>
                                <td>{{sale_point.state}}</td>
                                <td>
                                    <div class="btn-group">
                                        <button v-if="session_user.pages.aliance_edit"
                                        @click="modalUpdate(sale_point.id)" class="btn btn-primary" style="display: inline-flex; align-items: center;">
                                            <span class="material-icons-sharp active" style="font-size: 1.2rem;">&#xe3c9;</span>
                                        </button>
                                        <router-link v-if="session_user.pages.aliance_asignuser || session_user.pages.commission_show"
                                        to="/alianzas" class="btn btn-secondary submenu-btn" style="display: inline-flex; align-items: center;">
                                            <span class="material-icons-sharp active" style="font-size: 1.2rem;">&#xe8b8;</span>
                                            <div class="sub-menu-item">
                                                <div class="buttons">
                                                    <div class="triangle"></div>
                                                    <div class="links">
                                                        <div>
                                                            <div v-if="session_user.pages.aliance_asignuser"
                                                            @click="showAsignUserModal(sale_point)"
                                                            class="col-12" style="border-bottom: 1px solid var(--color-primary)">
                                                                <button>Asignar</button>
                                                            </div>
                                                            <router-link v-if="session_user.pages.aliance_payment"
                                                            :to="`/comisiones-cargadas/${sale_point.id}`"
                                                            class="col-12">
                                                                <button>Mostrar</button>
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-sm-7"></div>
                        <div class="col-sm-5">
                            <nav aria-label="Page navigation example" 
                            style="float: right;"
                            v-if="sale_points.total_pages < 4">
                                <ul class="pagination">
                                    <li class="page-item disabled">
                                        <a class="page-link" >Previous</a>
                                    </li>
                                    <li class="page-item" v-for="i in sale_points.total_pages" :key="i">
                                        <a class="page-link" @click="numPage(i)">{{i}}</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link">Next</a>
                                    </li>
                                </ul>
                            </nav>
                            <nav aria-label="Page navigation example" v-else>
                                <ul class="pagination">
                                    <li class="page-item" :class="{'disabled':validatePrevious()}">
                                        <a class="page-link" @click="previusPage()">Previous</a>
                                    </li>
                                    <li class="page-item" :class="{'active':validatePageNum()}">
                                        <a class="page-link" @click="firstPage()">1</a>
                                    </li>
                                    <li class="page-item disabled" v-if="pagination.selected >= sale_points.total_pages-1">
                                        <a class="page-link" >...</a>
                                    </li>
                                    <li class="page-item" v-if="pagination.selected == sale_points.total_pages">
                                        <a class="page-link" @click="numPage(sale_points.total_pages-1)">{{sale_points.total_pages-1}}</a>
                                    </li>
                                    <li class="page-item" :class="{'active':!validatePageNum()}">
                                        <a class="page-link" @click="numPage(pagination.show_num)">{{pagination.show_num}}</a>
                                    </li>
                                    <li class="page-item disabled" v-if="pagination.selected < sale_points.total_pages-1">
                                        <a class="page-link">...</a>
                                    </li>
                                    <li class="page-item" v-if="pagination.selected < sale_points.total_pages">
                                        <a class="page-link" @click="lastPage(sale_points.total_pages)">{{sale_points.total_pages}}</a>
                                    </li>
                                    <li class="page-item" :class="{'disabled':validateLast(sale_points.total_pages)}">
                                        <a class="page-link"  @click="nextPage(sale_points.total_pages)">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    
                </div>
                <div v-else style="display: flex; justify-content: center; ">
                    <div class="lds-dual-ring"></div>
                </div>
            </div>
        </main>
        <Right/>
        <!---------MODAL PARTNER---------->
        <div class="modal fade" id="modal-partnership" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" v-if="action_form == 'register'">Crear punto de venta</h3>
                        <h3 class="modal-title" v-else>Editar punto de venta</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="!sale_point.id && action_form != 'register'">
                        <Loading/>
                    </div>
                    <div class="modal-body" v-else>
                        <div class="row">
                            <div class="col-md-7">
                                <p>Nombre</p>
                                <input type="text" class="form-control" v-model="sale_point.name">
                            </div>
                            <div class="col-md-5">
                                <p>NIT</p>
                                <input type="text" class="form-control" v-model="sale_point.nit">
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-6">
                                <p>Departamento</p>
                                <select class="form-control" v-model="sale_point.department" @change="filterCitiesProfile(sale_point.department)">
                                    <option value="" disabled>Selecciona un Departamento</option>
                                    <option v-for="(deparment, index) in departments" :key="'a'+index">{{deparment.name}}</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <p>Ciudad</p>
                                <select class="form-control" v-model="sale_point.city_id">
                                    <option value="" disabled selected>Selecciona una ciudad</option>
                                    <option v-for="city in cities_department" :key="city.id" :value="city.id" >{{city.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <p>Dirección</p>
                                <input type="text" class="form-control" v-model="sale_point.adress">
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-sm-6">
                                <p>Representante</p>
                                <input type="text" class="form-control" v-model="sale_point.representative">
                            </div>
                            <div class="col-sm-6">
                                <p>Número de Contacto</p>
                                <input type="number" class="form-control" v-model="sale_point.contact_phone">
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div :class="col1">
                                <p>Tipo</p>
                                <select class="form-control" v-model="sale_point.type">
                                    <option value="aliance">Alianza</option>
                                    <option value="official">Oficial</option>
                                </select>
                            </div>
                            <div :class="col2">
                                <p>Modalidad</p>
                                <select class="form-control" 
                                    @change="validateModality(sale_point.modality)" 
                                    v-model="sale_point.modality">
                                    <option value="percents">Porcentaje</option>
                                    <option value="options">Plataforma</option>
                                    <option value="free">Libre</option>
                                </select>
                            </div>
                            <div class="col-3" v-if="sale_point.modality != 'free'">
                                <p>Opciones</p>
                                <button class="btn btn-secondary" style="width: 100%;" @click="ActiveOptionField()">Asignar</button>
                            </div>
                        </div>
                        <!----- PERCNETS FUNCIONS --- -->
                        <div v-if="sale_point.modality == 'percents'">
                            <div class="row mt-2" v-for="(percent, index) in percents" :key="index" >
                                <div class="col-4"></div>
                                <div class="col-5">
                                    <p class="form-control" style="cursor: pointer;">{{percent.percent}} %</p>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-danger" style="width: 100%;" @click="removePercent({index, id: percent.id})">x</button>
                                </div>
                            </div>
                            <!-------------------------------------------->
                            <div class="row mt-2" v-if="show_option_fields">
                                <div class="col-4"></div>
                                <div class="col-5">
                                    <input type="number" v-model="percent" class="form-control" placeholder="Digita el porcentaje"> 
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary" style="width: 100%;" @click="addPercent(percent)">+</button>
                                </div>
                            </div>
                            <!-- ------------------------------------------ -->
                        </div>
                        <div v-if="sale_point.modality == 'options'">
                            <div class="row mt-2" v-for="(option, index) in options" :key="index" >
                                <div class="col-4">
                                    <p class="form-control" style="cursor: pointer;">{{option.description}}</p>
                                </div>
                                <div class="col-5">
                                    <p class="form-control" style="cursor: pointer;">$ {{option.money}}</p>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-danger" style="width: 100%;" @click="removeOption({index, id: option.id})">x</button>
                                </div>
                            </div>
                            <!------------------------------------------->
                            <div class="row mt-2" v-if="show_option_fields">
                                <div class="col-4">
                                    <input type="text" v-model="option.description" class="form-control" placeholder="Digita la description"> 
                                </div>
                                <div class="col-5">
                                    <input type="number" v-model="option.money" class="form-control" placeholder="Valor de comisión"> 
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary" style="width: 100%;" @click="addOption(option)">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6">
                                <p>Fecha Inicio</p>
                                <input type="date" class="form-control" v-model="sale_point.start_date">
                            </div>
                            <div class="col-6">
                                <p>Fecha Fin</p>
                                <input type="date" class="form-control" v-model="sale_point.end_date">
                            </div>
                        </div>
                        <div class="row mt-1" v-if="action_form == 'update'">
                            <div class="col-12">
                                <p>Estado</p>
                                <select class="form-control" v-model="sale_point.state">
                                    <option value="active">Activo</option>
                                    <option value="pending">Pendiente</option>
                                    <option value="inactive">Inactivo</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-12 mt-2"><p><center>Relación de ganancias</center></p></div>
                            <div class="col-5">
                                <p>Comercializadora</p>
                                <input type="number" min="0" max="100" class="form-control" @keyup="calcPercent()" @change="calcPercent()" v-model="sale_point.percent_gain">
                            </div>
                            <div class="col-2"><center><p class="mt-4">a</p></center></div>
                            <div class="col-5">
                                <p>Comercializadora</p>
                                <p class="form-control">{{company_percent.toFixed(2)}}</p>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12" v-if="action_form == 'register'">
                                <button class="btn btn-primary" style="width: 100%;"
                                @click="register_SalePoint(sale_point)">Guardar</button>
                            </div>
                            <div class="col-md-12" v-else>
                                <button class="btn btn-primary" style="width: 100%;"
                                @click="update_SalePoint(sale_point)">Editar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!---------MODAL ASIGN USER TO SALE POINT---------->
        <div class="modal fade" id="modal-asign-user" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">Asignar encargado de {{asigned.sp_name}}</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <input type="number" v-model="search_code" class="form-control" placeholder="Buscar código a asignar...">
                            </div>
                            <div class="col-12">
                                <table class="table table-striped" v-if="users.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Nombre</th>
                                            <th>Correo</th>
                                            <th>Asignar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in users" :key="user.id">
                                            <td>{{user.id}}</td>
                                            <td>{{user.name}} {{user.last_name}}</td>
                                            <td>{{user.email}}</td>
                                            <td>
                                                <a @click="AsignUser(user)" class="btn btn-primary">Asignar</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr>
                        <h3>Usuarios Asignados</h3>
                        <hr>
                        <table class="table table-striped" v-if="this.asigned.users.length > 0">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in asigned.users" :key="user.id">
                                    <td>{{user.user_id}}</td>
                                    <td>{{user.name}} {{user.last_name}}</td>
                                    <td>{{user.email}}</td>
                                    <td>
                                        <div v-if="session_user.pages.aliance_removeuser"
                                        class="text-danger" style="cursor: pointer;"
                                        @click="removeUserOfPoint(user.id)">
                                            Remover
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else><p>No hay usuarios asignados</p></div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';
import Loading from '../components/Loading.vue'

export default {
    name: '',
    components: {
        Aside,
        Right,
        Loading
    },
    data: function(){
        return {
            pagination: {
                selected: 1,
                show_num: 2,
            },
            search_code : '',
            search_sp : '',
            users: [],
            asigned: {
                sp_id: '',
                sp_name: '',
                users: []
            },
            action_form : '',
            company_percent : 0,
            col1: 'col-4',
            col2: 'col-5',
            percent: '',
            option: {
                description: '',
                money: '',
            },
            percents: [],
            options: [],
            show_option_fields: false,
        }
    },
    methods: {
        modalUpdate(id){
            this.action_form = 'update';
            this.percents = [];
            this.options = [];
            $('#modal-partnership').modal('show');
            this.loadSalePoint(id);
        },
        //-------------
        searchUser(search){
            let url = this.api_url+'/api/search/public/users';
            axios.post(url, search, 
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                this.users = res.data;
            }).catch(e=> console.log(e.response))
        },
        loadAsignedUsers(id){
            let url = this.api_url+`/api/salepoints/${id}/asigned`;
            axios.post(url, null,
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                this.asigned.users = res.data;
            })
        },
        AsignUser(user){
            this.$swal.fire({
                title: `¿Deseas asignar asignar encargado a ${this.asigned.sp_name}?`,
                html: `<b>${user.name} ${user.last_name}</b><br>
                ¡El encargado obtendrá permisos para subir comisiones a esta alianza!`,
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = this.api_url+`/api/salepoints/asign_user`;
                    axios.post(url, {user_id: user.id, sale_point_id: this.asigned.sp_id},
                    { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(res => {
                        if(res.status == 201) {
                            this.loadAsignedUsers(this.asigned.sp_id);
                            return this.launchAlert({type: 'success', title: 'Usuario asignado exitosamente'})
                        }
                        console.log(res)
                    }).catch(e=> {
                        if(e.response.status == 400) return this.launchAlert({type: 'error', title: 'Usuario ya se encuentra asignado'})
                    });
                }
            })
        },
        removeUserOfPoint(id){
            let url = this.api_url+`/api/salepoints/remove_user/${id}`;
            axios.post(url, null,
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                if(res.status == 201) {
                    this.loadAsignedUsers(this.asigned.sp_id);
                    return this.launchAlert({type: 'success', title: 'Usuario eliminado exitosamente'})
                }
                console.log(res)
            });
        },
        showAsignUserModal(data){
            this.asigned.sp_id = data.id,
            this.asigned.sp_name = data.name,
            this.loadAsignedUsers(data.id)
            $('#modal-asign-user').modal('show');
        },
        validateModality(modality){
            if(modality != 'free') {this.col1 = 'col-4'; this.col2 = 'col-5';}
            else {this.col1 = 'col-6'; this.col2 = 'col-6';}
        },
        ActiveOptionField(){
            if(this.show_option_fields) return this.show_option_fields = false;
            this.show_option_fields = true; 
        },
        addPercent(percent){
            if(percent > 0) {
                if(this.action_form == 'update'){
                    let json = {id:this.sale_point.id, percent: percent}
                    this.updateSalePoint(this.sale_point).then(res=>{
                        if(res.status == 201){
                            this.createPercentOption(json).then((res) => {
                                if(res.status == 201){
                                    this.percents.push({id: res.data.data.id, percent}); 
                                    this.show_option_fields = false;
                                    this.percent = '';
                                    this.options = [];
                                    return this.launchAlert({type: 'success', title: 'Porcentaje agregado'})
                                }
                            }).catch(err => console.log(err.response));
                        }
                    }).catch(err => console.log(err.response));
                }  else {
                    this.percents.push({id:'', percent}); 
                    this.show_option_fields = false;
                    this.percnet = '';
                    return this.launchAlert({type: 'success', title: 'Porcentaje agregado'})
                }
            } else return this.launchAlert({type: 'warning', title: 'Digita un porcentaje válido'})
        },
        addOption(option){
            if(!option.description) return this.launchAlert({type: 'warning', title: 'Digita una descripción'});
            let money = parseInt(option.money);
            if(money <= 0) return this.launchAlert({type: 'warning', title: 'Digita una comisión válida'});
            if(this.action_form == 'update'){
                let json = {id:this.sale_point.id, description: option.description, money};
                this.updateSalePoint(this.sale_point).then(res=>{
                    if(res.status == 201){
                        this.createMoneyOption(json).then(res => {
                            if(res.status == 201) {
                                this.options.push({description: option.description, money});
                                this.percents = [];
                                this.option.money = '';
                                this.option.description = '';
                                this.show_option_fields = false;
                                this.loadSalePoint(this.sale_point.id);
                                return this.launchAlert({type: 'success', title: 'Opción agregada exitosamente'});
                            }
                        }).catch(err => {console.log(err.response)})
                    }
                }).catch(err => {console.log(err.response)});
            } else {
                this.options.push({description: option.description, money});
                this.option.money = '';
                this.option.description = '';
                this.show_option_fields = false;
                return this.launchAlert({type: 'success', title: 'Opción agregada exitosamente'});
            }
        },
        removeOption(data){
            if(this.action_form == 'update'){
                this.deleteMoneyOption(data.id).then(res => {
                    if(res.status == 201) return this.options.splice(data.index, 1);
                }).catch(err => console.log(err.response));
            } else {
                this.options.splice(data.index, 1);
            }
        },
        removePercent(data){
            if(this.action_form == 'update'){
                this.deletePercentOption(data.id).then(res => {
                    if(res.status == 201) return this.percents.splice(data.index, 1);
                }).catch(err => console.log(err));
            } else {
                this.percents.splice(data.index, 1);
            }
        },
        modalRegister(){
            this.clearFields();
            this.validateModality('percents');
            this.action_form = 'register';
            $('#modal-partnership').modal('show');
        },
        /* -----CREATE------- */
        register_SalePoint(data) {
            if(!data.name) return this.launchAlert({type: 'warning', title: 'El nombre es requerido'})
            if(!data.city_id) return this.launchAlert({type: 'warning', title: 'La ciudad es requerida'})
            if(!data.start_date) return this.launchAlert({type: 'warning', title: 'La fecha de inicio es requerida'})
            if(!data.end_date) return this.launchAlert({type: 'warning', title: 'La fecha de fin es requerida'})
            if(!data.percent_gain) return this.launchAlert({type: 'warning', title: 'El porcentaje de ganancia es requerido'});
            /* ---------OPTIONS VALIDATION------------ */
            if(data.modality == 'percents' && this.percents.length <= 0) return this.launchAlert({type: 'warning', title: 'Es necesario digitar al menos un porcentaje'}) 
            if(data.modality == 'options' && this.options.length <= 0) return this.launchAlert({type: 'warning', title: 'Es necesario digitar al menos una opción'}) 
            this.createSalePoint(data).then(res => {
                if(res.status == 201){
                    let percents = this.percents;
                    let options = this.options;
                    if(data.modality == 'percents'){
                        percents.forEach(element => {
                            let json = {id:res.data.data.id, percent: element.percent}
                            this.createPercentOption(json)
                        })
                    }
                    if(data.modality == 'options'){
                        options.forEach(element => {
                            let money = parseInt(element.money);
                            let json = {id:res.data.data.id, description: element.description, money};
                            this.createMoneyOption(json).then()
                        })
                    }
                    this.clearFields();
                    this.loadSalePoints();
                    $('#modal-partnership').modal('hide');
                    return this.launchAlert({type: 'success', title: 'Alianza registrada exitosamente'})
                }
            }).catch(err => console.log(err));
        },
        /* -----UPDATE------- */
        update_SalePoint(data) {
            /* ---------OPTIONS VALIDATION------------ */
            if(data.modality == 'percents' && this.percents.length <= 0) return this.launchAlert({type: 'warning', title: 'Es necesario digitar al menos un porcentaje'}) 
            if(data.modality == 'options' && this.options.length <= 0) return this.launchAlert({type: 'warning', title: 'Es necesario digitar al menos una opción'}) 
            /* --------------------------------------- */
            this.updateSalePoint(data).then(res=>{
                if(res.status == 201){
                    this.loadSalePoints();
                    $('#modal-partnership').modal('hide');
                    this.clearFields();
                    return this.launchAlert({type: 'success', title: 'Alianza actualizada exitosamente'})
                }
                return this.launchAlert({type: 'error', title: 'Surgió un error'})
            }).catch(err => {
                console.log(err.response)
            })
        },
        /* --------------- */
        clearFields(){
            this.sale_point.id = ''
            this.sale_point.name = '';
            this.sale_point.nit = '';
            this.sale_point.city_id = '';
            this.sale_point.department = '';
            this.sale_point.adress = '';
            this.sale_point.representative = '';
            this.sale_point.contact_phone = '';
            this.sale_point.percent_gain = 50;
            this.sale_point.start_date = '';
            this.sale_point.end_date = '';
            this.sale_point.type = 'aliance';
            this.sale_point.modality = 'percents';
            this.sale_point.state = '';
            this.sale_point.code = '';
            this.sale_point.created_at = '';
            this.company_percent = 50;

            this.options = [];
            this.percents = [];
            this.company_percent = 50;
        },
        filterCitiesProfile(department){
            this.sale_point.city_id = '';
            this.filterCities(department)
        },
        calcPercent(){
            this.company_percent = 100 - this.sale_point.percent_gain;
        },
        // STYLE
        validatePrevious(){
            if(this.pagination.selected <= 1) return true;
            else return false;
        },
        validateLast(num){
            if(this.pagination.selected >= num) return true;
            else return false;
        },
        validatePageNum(){
            if(this.pagination.selected == 1) return true;
            else return false;
        },
        firstPage(){
            this.pagination.selected = 1;
            this.pagination.show_num = 2;
            this.loadSalePoints({limit: 10, page: this.pagination.selected})
        },
        previusPage(){
            if(this.pagination.selected > 1) this.pagination.selected--;
            if(this.pagination.selected >= 2) this.pagination.show_num--;
            this.loadSalePoints({limit: 10, page: this.pagination.selected})
        },
        numPage(num){
            this.pagination.show_num = num;
            this.pagination.selected = num;
            this.loadSalePoints({limit: 10, page: this.pagination.selected})
        },
        lastPage(last){
            this.pagination.show_num = last;
            this.pagination.selected = last;
            this.loadSalePoints({limit: 10, page: this.pagination.selected})
        },  
        nextPage(max){
            if(this.pagination.selected >= max);
            else this.pagination.selected++;
            if(this.pagination.selected > 2) this.pagination.show_num++;
            this.loadSalePoints({limit: 10, page: this.pagination.selected})
        },
        launchAlert(config){
            if(!config.timeout) config.timeout = 2500;
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: config.timeout,
            })
            Toast.fire({
                icon: config.type,
                title: config.title,
                text: config.message,
            })
        },
        ...mapMutations(['loadSessionUser']),
        ...mapActions("sale_point", ["createSalePoint"]),
        ...mapActions("sale_point", ["updateSalePoint"]),
        ...mapActions("sale_point", ["createPercentOption"]),
        ...mapActions("sale_point", ["deletePercentOption"]),
        ...mapActions("sale_point", ["createMoneyOption"]),
        ...mapActions("sale_point", ["deleteMoneyOption"]),
        ...mapMutations("sale_point", ["loadSalePoint"]),
        ...mapMutations("sale_point", ["loadSalePoints"]),
        ...mapMutations("country", ["getCountryCities"]),
        ...mapMutations("country", ["filterCities"]),
    },
    mounted: function(){
        $('.modal-backdrop').remove();
        this.loadSalePoints({limit: 10});
        this.loadSessionUser();
        this.getCountryCities('COL');
    },
    computed: {
        ...mapState(['api_url']),
        ...mapState(['session_user']),
        ...mapState('sale_point', ['sale_point']),
        ...mapState('sale_point', ['sale_points']),
        ...mapState('sale_point', ['loaded']),
        ...mapState("country", ["departments"]),
        ...mapState("country", ["cities_department"]),
    },
    watch: {
        search_code: function (){
            this.searchUser({search : this.search_code, order: 'ASC', limit: 1});
        },
        search_sp: function (){
            this.loadSalePoints({limit: 10, search: this.search_sp});
        },      
        'sale_point.id': function(){
            if(this.sale_point.id){
                this.calcPercent();
                this.filterCities(this.sale_point.department);
                if(this.sale_point.modality == 'percents') this.percents = this.sale_point.options;
                if(this.sale_point.modality == 'options') this.options = this.sale_point.options;
                this.validateModality(this.sale_point.modality);
            }
        }  
    }
}
</script>

<style>
.pagination{
    user-select: none;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.pagination > li a{
    cursor: pointer;
}
.submenu-btn{
    position: relative;
}
.submenu-btn:hover .sub-menu-item{
    display: block;
}
.submenu-btn .sub-menu-item{
    left: -10px;
    bottom: -25px;
    position: absolute;
    width: 120px;
    display: none;
    z-index: 10;
}
.submenu-btn .sub-menu-item .buttons {
    position: relative;
    background: #f6f6f9;
    border: 1px solid var(--color-primary);
}
.submenu-btn .sub-menu-item .buttons .links{
    display: block;
    text-align: center;
    transition: all .3s;
}
.submenu-btn .sub-menu-item .buttons .links button{
    padding: .1rem;
    transition: all .3s;
    color: var(--color-primary);
}
.submenu-btn .sub-menu-item .buttons .links button:hover{
    opacity: .3;
}
</style>